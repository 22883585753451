import React from 'react';
import PropTypes from 'prop-types';
import { API_URL } from '../../api/axios';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
const Description = ({ image, icon, name, description }) => {
  return (
    <div className="py-20-xxl py-12-lg py-6 container px-6 w-90-lg w-full mx-auto">
      <div className="grid grid-cols-2-lg place-items-center max-w-1200 mx-auto pt-12-lg px-8-lg">
        <div className="w-90 w-full h-full relative me-auto-lg ms-0-lg mx-auto">
          <div className="h-75 w-75 border-4 border-blue-400 absolute -top-7 -left-10 -z-10" />
          <img
            src={`${API_URL}${image?.data?.attributes?.url}`}
            className="h-full max-h-full max-w-full object-cover"
          />
        </div>
        <div className="flex flex-col justify-start items-start w-90-lg ms-auto py-8">
          <span className="text-2xl-xxl text-2xl-lg text-xl text-blue-800 font-bold mb-6">
            Produits utilisés
          </span>
          <span className="text-xl-xxl text-xl-lg text-lg text-blue-400 font-semibold mb-6 flex items-center gap-4">
            <img
              src={`${API_URL}${icon?.data?.attributes?.url}`}
              width={41}
              height={40}
              className="object-contain"
              alt={name}
            />
            {name}
          </span>
          {description && (
            <div className="font-light text-blue-800 text-xl-xxl text-lg-lg text-base">
              <BlocksRenderer content={description} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Description.propTypes = {
  image: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.array,
};

export default Description;
