import React from 'react';
import PropTypes from 'prop-types';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const InfoCard = ({ title, list }) => {
  if (!list?.length) return null;
  return (
    <div className="py-20-xxl py-12 container px-6 w-90-lg w-full mx-auto">
      <div className="flex flex-col border-2 border-gray-50 p-16-xxl p-10-lg p-6 text-center">
        <span className="font-semibold text-2xl-xxl text-xl text-blue-400 mb-6">
          {title}
        </span>
        <div className="flex flex-col gap-4">
          {list.map(({ label, value, isRichText = false }) => (
            <div
              key={value}
              className="flex flex-col flex-row-lg items-center justify-center"
            >
              <span className="text-xl-xxl text-lg font-semibold">
                {label} :
              </span>
              <span className="text-xl-xxl text-lg-lg text-base font-light inline-block ms-1">
                {isRichText ? <BlocksRenderer content={value} /> : value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  isRichText: PropTypes.bool,
};

export default InfoCard;
